import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  TRANSITION_ENQUIRE,
  TRANSITION_EXPIRE,
  TRANSITION_VIDEO_CALLS_EXPIRE,
  TRANSITION_APPOINTMENTS_EXPIRE,
  TRANSITION_HOME_VISITS_EXPIRE,
  TRANSITION_DECLINE,
  TRANSITION_HOME_VISITS_DECLINE,
  TRANSITION_VIDEO_CALLS_DECLINE,
  TRANSITION_APPOINTMENTS_DECLINE,
  TRANSITION_CANCEL,
  TRANSITION_VIDEO_CALLS_CANCEL,
  TRANSITION_APPOINTMENTS_CANCEL,
  TRANSITION_HOME_VISITS_CANCEL,
  TRANSITION_VIDEO_CALLS_ACCEPT,
  TRANSITION_VIDEO_CALLS_COMPLETE,
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_OPERATOR,
  TRANSITION_REQUEST_PAYMENT,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  Modal,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';

import css from './TransactionPanel.module.css';
import SendFilesForm from './SendFilesForm';
import Accordion from './Accordion';
import axios from 'axios';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import IconSpinner from '../IconSpinner/IconSpinner';
import ViewAnimal from './ViewAnimal';
import { IoShareOutline, IoShareSocialOutline } from 'react-icons/io5';
import {
  createBooking,
  createListing,
  createTransaction,
  createTxTransition,
  createUser,
} from '../../util/test-data';

const getTx = transaction => {
  const tx = createTransaction({
    id: '123',

    lastTransition: TRANSITION_VIDEO_CALLS_COMPLETE,
    listing: transaction.listing,
    customer: transaction.customer,
    provider: transaction.provider,
    booking: transaction.booking,
    lastTransitionedAt: new Date(),
    transitions: [
      createTxTransition({
        createdAt: new Date(Date.UTC(2017, 4, 1)),
        by: TX_TRANSITION_ACTOR_CUSTOMER,
        transition: TRANSITION_REQUEST_PAYMENT,
      }),
      // createTxTransition({
      //   createdAt: new Date(Date.UTC(2017, 4, 1, 0, 0, 1)),
      //   by: TX_TRANSITION_ACTOR_CUSTOMER,
      //   transition: TRANSITION_CONFIRM_PAYMENT,
      // }),
      createTxTransition({
        createdAt: new Date(Date.UTC(2017, 5, 1)),
        by: TX_TRANSITION_ACTOR_PROVIDER,
        transition: TRANSITION_VIDEO_CALLS_ACCEPT,
      }),
      createTxTransition({
        createdAt: new Date(Date.UTC(2017, 5, 1)),
        by: TX_TRANSITION_ACTOR_OPERATOR,
        transition: TRANSITION_VIDEO_CALLS_COMPLETE,
      }),
    ],
  });
  return tx;
};

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      showShareModal: false,
      showShareAnimalModal: false,
      shareAnimal: '',
      shareAnimalError: null,
      shareAnimalInProgress: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';
    this.sendFilesFormName = 'TransactionPanel.SendFilesForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview('customer', currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      handleFilesFormSubmit,
      sendFilesInProgress,
      sendFilesError,
      onDelete,
      onDeleteSuccess,
      onDeleteError,
      deleteFileInProgress,
      deleteFileError,
    } = this.props;

    // const tx = getTx(transaction);

    // const currentTransaction = ensureTransaction(tx);
    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const isDoctor = currentListing?.attributes.metadata?.doctor;
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : isDoctor
      ? `Dr. ${currentListing.attributes.title}`
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction)}
        onDeclineSale={() => onDeclineSale(currentTransaction)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);
    const showAmount =
      currentTransaction.attributes?.protectedData?.service == config.chargedService;

    const { prescriptions, reports } = currentTransaction?.attributes?.metadata ?? {};

    const accordionContent = [
      { title: 'Prescriptions', data: prescriptions },
      { title: 'Reports', data: reports },
    ];

    const defaultAnimal = currentTransaction?.attributes?.protectedData?.animal;
    const { shared_animals = [] } = currentTransaction?.attributes?.metadata ?? {};

    const removeSharedAnimals = animal => {
      const alreadySharedAnimals = [
        ...(defaultAnimal ? [defaultAnimal] : []),
        ...shared_animals,
      ].map(a => a.id ?? a);
      return alreadySharedAnimals.includes(animal.id) ? false : true;
    };

    const modalContent = (
      <Modal
        id="shareReportModal"
        name="shareReportModal"
        isOpen={this.state.showShareModal}
        onManageDisableScrolling={onManageDisableScrolling}
        onClose={() => this.setState({ showShareModal: false })}
        usePortal
        contentClassName={css.modalContent}
      >
        <div className={css.modalContainer}>
          <SendFilesForm
            formId={this.sendFilesFormName}
            rootClassName={css.sendMessageForm}
            inProgress={sendFilesInProgress}
            sendFilesError={sendFilesError}
            onSubmit={(values, form) =>
              handleFilesFormSubmit({
                ...values,
                txnId: currentTransaction.id.uuid,
                role: transactionRole,
              })
                .then(() => {
                  form.reset();
                  this.setState({ showShareModal: false });
                  // document.forms[this.sendFilesFormName].reset();
                })
                .catch(() => {
                  console.log();
                })
            }
          />
        </div>
      </Modal>
    );

    const handleShareAnimalSubmit = async () => {
      if (!this.state.shareAnimal) return;
      this.setState({ shareAnimalInProgress: true, shareAnimalError: null });

      try {
        const response = await axios.post('/share-animal', {
          id: this.state.shareAnimal,
          txnId: currentTransaction.id.uuid,
        });

        this.props.history.replace(this.props.location.pathname);
        this.setState({ shareAnimal: null, showShareAnimalModal: false });
      } catch (e) {
        console.log(e);
        this.setState({ shareAnimalError: e?.response?.data?.message || e?.message });
      } finally {
        this.setState({ shareAnimalInProgress: false });
      }
    };

    const shareAnimalForm = (
      <div>
        <h3>{intl.formatMessage({ id: 'TransactionPanel.shareAnimalModalHeading' })}</h3>
        <p className="leading-5 text-gray-600">
          {intl.formatMessage({ id: 'TransactionPanel.shareAnimalModalDescription' })}
        </p>
        <select
          onChange={e => this.setState({ shareAnimal: e.target.value })}
          value={this.state.shareAnimal}
        >
          <option value="" disabled selected>
            {intl.formatMessage({ id: 'TransactionPanel.shareAnimalSelectPlaceholder' })}
          </option>
          {(currentUser.attributes.profile.privateData.animals ?? [])
            ?.filter(removeSharedAnimals)
            .map(animal => (
              <option value={animal.id} key={animal.id}>
                {animal.animal_name}
              </option>
            ))}
        </select>
        <button
          type="button"
          onClick={handleShareAnimalSubmit}
          disabled={!this.state.shareAnimal}
          className={`mt-6 bg-marketplaceColor 
          ${
            this.state.shareAnimalInProgress
              ? 'disabled:bg-marketplaceColorLight disabled:border-marketplaceColorLight disabled:cursor-not-allowed'
              : 'disabled:bg-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed'
          }
          border border-solid block  shadow rounded py-3 border-marketplaceColor focus:border-marketplaceColorDark text-white font-semibold w-full cursor-pointer hover:border-marketplaceColorDark hover:bg-marketplaceColorDark focus:bg-marketplaceColorDark`}
        >
          {this.state.shareAnimalInProgress ? (
            <IconSpinner className="text-marketplaceColorDark" />
          ) : (
            intl.formatMessage({ id: 'TransactionPanel.shareAnimalModalButton' })
          )}
        </button>
      </div>
    );

    const shareAnimalModalContent = (
      <Modal
        id="share-animal-modal-content"
        name="share-animal-modal-content"
        isOpen={this.state.showShareAnimalModal}
        onManageDisableScrolling={onManageDisableScrolling}
        onClose={() => this.setState({ showShareAnimalModal: false })}
        usePortal
        contentClassName={css.modalContent}
      >
        <div className={css.modalContainer}>
          {shareAnimalForm}
          {/* <SendFilesForm
        formId={this.sendFilesFormName}
        rootClassName={css.sendMessageForm}
        inProgress={sendFilesInProgress}
        sendFilesError={sendFilesError}
        onSubmit={(values, form) =>
          handleFilesFormSubmit({
            ...values,
            txnId: currentTransaction.id.uuid,
            role: transactionRole,
          })
            .then(() => {
              form.reset();
              this.setState({ showShareModal: false });
              // document.forms[this.sendFilesFormName].reset();
            })
            .catch(() => {
              console.log();
            })
        }
      /> */}
        </div>
      </Modal>
    );

    const lastTransition = currentTransaction?.attributes?.lastTransition;

    const isEnquire = lastTransition == TRANSITION_ENQUIRE;

    const declineTransitions = [
      TRANSITION_EXPIRE,
      TRANSITION_VIDEO_CALLS_EXPIRE,
      TRANSITION_APPOINTMENTS_EXPIRE,
      TRANSITION_HOME_VISITS_EXPIRE,
      TRANSITION_DECLINE,
      TRANSITION_HOME_VISITS_DECLINE,
      TRANSITION_VIDEO_CALLS_DECLINE,
      TRANSITION_APPOINTMENTS_DECLINE,
      TRANSITION_CANCEL,
      TRANSITION_VIDEO_CALLS_CANCEL,
      TRANSITION_APPOINTMENTS_CANCEL,
      TRANSITION_HOME_VISITS_CANCEL,
    ];

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
              listingId={currentListing.id && currentListing.id.uuid}
              listingDeleted={listingDeleted}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              {defaultAnimal ? (
                <ViewAnimal
                  currentTransaction={currentTransaction}
                  defaultAnimal={defaultAnimal}
                  rootClassName={css.addressMobile}
                />
              ) : null}
              <BreakdownMaybe
                transaction={currentTransaction}
                showAmount={showAmount}
                transactionRole={transactionRole}
              />
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              currentRole={isProvider ? 'provider' : 'customer'}
            />

            {/* <div className={css.sendMessageForm}>
              <Accordion
                currentUser={currentUser}
                accordionContent={accordionContent}
                onDelete={onDelete}
                transactionRole={transactionRole}
                transaction={currentTransaction}
                deleteFileInProgress={deleteFileInProgress}
                deleteFileError={deleteFileError}
              />
            </div> */}

            {/* {isEnquire ? null : declineTransitions.includes(lastTransition) ? null : (
              <React.Fragment>
                <div
                  className={css.sendMessageForm}
                  onClick={() => this.setState({ showShareModal: true })}
                >
                  <button
                    type="button"
                    className="bg-white border flex items-center justify-center border-dashed block shadow rounded py-3 border-gray-300 focus:border-marketplaceColorDark text-gray-500 w-full text-base font-semibold text-sm cursor-pointer hover:border-gray-300 hover:bg-gray-100 focus:bg-gray-100"
                  >
                    <IoShareOutline className="text-xl mx-2 font-semibold" />
                    {intl.formatMessage({ id: 'TransactionPanel.shareFilesButtonText' })}
                  </button>
                </div>
                {transactionRole == 'customer' &&
                (currentUser.attributes.profile.privateData.animals ?? [])?.filter(
                  removeSharedAnimals
                ).length > 0 ? (
                  <div
                    className={classNames(css.sendMessageForm, 'mt-0 flex block flex-col')}
                    onClick={() => this.setState({ showShareAnimalModal: true })}
                  >
                    <span className="self-center leading-loose my-4 text-gray-400 text-lg text-semibold tracking-wide">
                      {intl.formatMessage({ id: 'TransactionPanel.orButtonLabel' })}
                    </span>

                    <button
                      type="button"
                      className="bg-white border border-dashed flex items-center justify-center block shadow rounded py-2 border-gray-200 focus:border-gray-400 text-gray-500 font-semibold w-full cursor-pointer hover:border-gray-400 hover:bg-gray-100 focus:bg-gray-100 transition duration-200"
                    >
                      <IoShareSocialOutline className="text-xl mx-2" />

                      {intl.formatMessage({ id: 'TransactionPanel.shareAnimalButtonText' })}
                    </button>
                  </div>
                ) : null}
              </React.Fragment>
            )} */}

            {/* {modalContent} */}
            {/* {shareAnimalModalContent} */}

            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                defaultAnimal={defaultAnimal}
                currentTransaction={currentTransaction}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
                transactionRole={transactionRole}
              />
              {stateData.showBookingPanel && false ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
              ) : null}

              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                showAmount={showAmount}
              />

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = compose(
  withRouter,
  injectIntl
)(TransactionPanelComponent);

export default TransactionPanel;
