import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';
import { injectIntl } from '../../util/reactIntl';
import { useHistory } from 'react-router';
import AddressLinkMaybe from './AddressLinkMaybe';

import css from './TransactionPanel.module.css';

const getAnimalId = (animal, customer) => {
  return animal.replace(customer.id.uuid, '').replace(':id:', '');
};

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    listingTitle,
    subTitle,
    location,
    geolocation,
    showAddress,
    defaultAnimal,
    currentTransaction,
    transactionRole,
    intl,
  } = props;
  const history = useHistory();

  // const [animalId, uid] = defaultAnimal.split(':id:');
  // const [viewAnimal, setViewAnimal] = useState('');
  // const { shared_animals = [] } = currentTransaction.attributes.metadata;
  // const animals = [...(defaultAnimal ? [defaultAnimal] : []), ...shared_animals];

  const viewAnimalHeading = intl.formatMessage({ id: 'ViewAnimal.viewAnimalHeading' });
  const selectAnimalPlaceholder = intl.formatMessage({ id: 'ViewAnimal.selectAnimalPlaceholder' });
  const viewAnimalButtonText = intl.formatMessage({ id: 'ViewAnimal.viewAnimalButtonText' });

  const showData =
    // animals?.length > 0 ? (
    // <React.Fragment>
    //   <h5 className="my-0 text-gray-500 font-semibold">{viewAnimalHeading}</h5>
    //   <select value={viewAnimal} onChange={e => setViewAnimal(e.target.value)}>
    //     <option value="">{selectAnimalPlaceholder}</option>
    //     {animals.map(animal => (
    //       <option key={animal} value={animal}>
    //         {getAnimalId(animal, currentTransaction.customer)}
    //       </option>
    //     ))}
    //   </select>

    defaultAnimal ? (
      <p
        className={classNames(
          `text-white py-4 text-center font-semibold rounded shadow focus:bg-gray-800 hover:bg-gray-800  my-0 text-sm mt-4 font-bold cursor-pointer bg-black ${
            defaultAnimal ? '' : 'bg-gray-200 border-gray-200 cursor-not-allowed hover:bg-gray-200'
          }`
        )}
        onClick={() => {
          // const [animalId, uid] = viewAnimal.split(':id:');
          // if (!animalId || !uid) return;
          if (!defaultAnimal) return;
          history.push({
            pathname: `/view/${defaultAnimal}`,
            // pathname: `/view/${uid}/${animalId}`,
            state: {
              transaction: JSON.stringify(currentTransaction),
            },
          });
        }}
      >
        {viewAnimalButtonText}
      </p>
    ) : null;
  // </React.Fragment>
  // ) : null;

  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      <h2 className={css.detailCardTitle}>{listingTitle}</h2>
      {/* <p className={css.detailCardSubtitle}>{subTitle}</p> */}
      {showData}
      {/* <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} /> */}
    </div>
  ) : transactionRole == 'provider' ? (
    <div className={css.detailCardHeadings}>
      {showData && <div className="mt-6">{showData}</div>}
    </div>
  ) : null;
};

export default injectIntl(DetailCardHeadingsMaybe);
