import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { divideInSlots, timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, IconSpinner, PrimaryButton } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';
import moment from 'moment';
import { connect } from 'react-redux';
import { ensureCurrentUser } from '../../util/data';
import _ from 'lodash';
import timezoneMoment from 'moment-timezone';

const getServiceEntryAsArray = serviceEntry => {
  let start = Number(serviceEntry.startTime.split(':')[0]);
  const end =
    Number(serviceEntry.endTime.split(':')[0]) == 0
      ? 24
      : Number(serviceEntry.endTime.split(':')[0]);
  let range = [];
  while (start <= end) {
    if (String(start).length > 1) {
      range.push(`${start}:00`);
    } else {
      range.push(`0${start}:00`);
    }
    start += 1;
  }
  return range;
};

const commonFilterFunction = (serviceEntry, v) => {
  const {
    attributes: { start, end },
  } = v;

  const serviceEntryAsArray = getServiceEntryAsArray(serviceEntry);
  const timeDividedIn60MinutesSlots = divideInSlots(start, end, 60);

  const filtered = timeDividedIn60MinutesSlots.filter(d => {
    // TODO: IF ANY ISSUE HAPPENS WITH TIMEZONE UNCOMMENT THIS, THIS FIXES DIFFERENT TIMEZONE ISSUE
    // const momentStart = moment(d.start).format('HH:mm');
    // const momentEnd = moment(d.end).format('HH:mm');
    // const momentStartDay = moment(d.start)
    //   .format('ddd')
    //   .toLowerCase();
    // const momentEndDay = moment(d.end)
    //   .format('ddd')
    //   .toLowerCase();

    // FIXME: USED THIS CODE INSTEAD OF ABOVE CODE
    const momentStart = timezoneMoment
      .tz(new Date(d.start).toUTCString(), config.appTimezone)
      .format('HH:mm');
    const momentEnd = timezoneMoment
      .tz(new Date(d.end).toUTCString(), config.appTimezone)
      .format('HH:mm');
    const momentStartDay = timezoneMoment
      .tz(new Date(d.start).toUTCString(), config.appTimezone)
      .format('ddd')
      .toLowerCase();
    // -1 just to fix timebug
    const momentEndDay = timezoneMoment
      .tz(new Date(moment(d.end).valueOf() - 1).toUTCString(), config.appTimezone)
      .format('ddd')
      .toLowerCase();

    return (
      serviceEntry.dayOfWeek == momentStartDay &&
      serviceEntry.dayOfWeek == momentEndDay &&
      serviceEntryAsArray.includes(momentStart) &&
      serviceEntryAsArray.includes(momentEnd)
    );
  });

  // console.log({ serviceEntry, serviceEntryAsArray, filtered });

  let result;
  //if slots are found they are continuous, make a single slot of their length and return it
  if (filtered.length > 0) {
    result = {
      ...v,
      attributes: {
        ...v.attributes,
        start: new Date(filtered[0].start),
        end: new Date(filtered[filtered.length - 1].end),
      },
    };
  }

  return result;

  // console.log(_.groupBy(filtered, 'startDay'));

  // const momentStart = moment(start).format('HH:mm');
  // const momentEnd = moment(end).format('HH:mm');
  // const momentStartDay = moment(start)
  //   .format('ddd')
  //   .toLowerCase();
  // const momentEndDay = moment(end)
  //   .format('ddd')
  //   .toLowerCase();
  // return (
  //   serviceEntry.dayOfWeek == momentStartDay &&
  //   serviceEntry.dayOfWeek == momentEndDay &&
  //   serviceEntryAsArray.includes(momentStart) &&
  //   serviceEntryAsArray.includes(momentEnd)
  // );
};

const filteredTimeSlotsBasedOnService = (serviceEntry, [monthId, monthValues]) => {
  const values = monthValues?.timeSlots ?? [];
  let filtered = [];
  serviceEntry.forEach(
    s => (filtered = [...filtered, ...values?.filter(v => commonFilterFunction(s, v))])
  );
  return filtered.length > 0 ? true : false;
};
// const filteredTimeSlotsBasedOnService = (serviceEntry, [monthId, monthValues]) => {
//   const values = monthValues?.timeSlots ?? [];
//   const filtered = values?.filter(v => commonFilterFunction(serviceEntry, v));
//   return filtered.length > 0 ? true : false;
// };

const mapTimeSlotsToTheirMonthIds = (serviceEntry, [key, value]) => {
  const values = value?.timeSlots ?? [];
  let filtered = [];
  serviceEntry.forEach(
    s =>
      (filtered = [
        ...filtered,
        ...values?.filter(v => commonFilterFunction(s, v)).map(v => commonFilterFunction(s, v)),
      ])
  );

  // console.log({ [key]: filtered });
  // const filtered = values?.filter(v => commonFilterFunction(serviceEntry, v));
  return { [key]: { ...value, timeSlots: filtered } };
};

const reduceToObject = (acc, item) => ({ ...acc, ...item });

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newMonthlyTimeSlots: [],
      activeService: null,
      mounted: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.changeTimeSlots = this.changeTimeSlots.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  componentDidMount() {
    this.setState({ newMonthlyTimeSlots: this.props.monthlyTimeSlots, mounted: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const isEqual = _.isEqual(prevProps.monthlyTimeSlots, this.props.monthlyTimeSlots);
    if (this.state.mounted && !isEqual && this.state.activeService) {
      const label = this.state.activeService;
      const allListingEntries = this.props.listing.attributes?.publicData?.entries?.availabilityPlan
        ?.entries;
      const requiredEntry = allListingEntries.filter(f => f.service == label);
      if (requiredEntry.length > 0) {
        const selectedOptionTimeSlots = Object.entries({ ...this.props.monthlyTimeSlots })
          .filter(([monthId, monthValues]) =>
            filteredTimeSlotsBasedOnService(requiredEntry, [monthId, monthValues])
          )
          .map(([key, value]) => mapTimeSlotsToTheirMonthIds(requiredEntry, [key, value]))
          .reduce(reduceToObject, {});
        this.setState(prevState => {
          return {
            ...prevState,
            newMonthlyTimeSlots: {
              // ...prevState.newMonthlyTimeSlots,
              ...selectedOptionTimeSlots,
            },
          };
        });
      } else {
        this.setState({ newMonthlyTimeSlots: [] });
      }
      // const requiredEntry = allListingEntries.find(f => f.service == label);
      // if (requiredEntry) {
      //   const selectedOptionTimeSlots = Object.entries({ ...this.props.monthlyTimeSlots })
      //     .filter(([monthId, monthValues]) =>
      //       filteredTimeSlotsBasedOnService(requiredEntry, [monthId, monthValues])
      //     )
      //     .map(([key, value]) => mapTimeSlotsToTheirMonthIds(requiredEntry, [key, value]))
      //     .reduce(reduceToObject, {});
      //   this.setState(prevState => {
      //     return {
      //       ...prevState,
      //       newMonthlyTimeSlots: { ...prevState.newMonthlyTimeSlots, ...selectedOptionTimeSlots },
      //     };
      //   });
      // } else {
      //   this.setState({ newMonthlyTimeSlots: [] });
      // }
    }
  }

  changeTimeSlots(label) {
    const allListingEntries = this.props.listing.attributes?.publicData?.entries?.availabilityPlan
      ?.entries;
    // console.log(allListingEntries);
    const requiredEntry = allListingEntries.filter(f => f.service == label);
    // const requiredEntry = allListingEntries.find(f => f.service == label);
    // if (requiredEntry) {
    //   const selectedOptionTimeSlots = Object.entries(this.props.monthlyTimeSlots)
    //     .filter(([monthId, monthValues]) =>
    //       filteredTimeSlotsBasedOnService(requiredEntry, [monthId, monthValues])
    //     )
    //     .map(([key, value]) => mapTimeSlotsToTheirMonthIds(requiredEntry, [key, value]))
    //     .reduce(reduceToObject, {});
    //   this.setState({ newMonthlyTimeSlots: selectedOptionTimeSlots, activeService: label });
    // } else {
    //   this.setState({ newMonthlyTimeSlots: [], activeService: label });
    // }
    if (requiredEntry.length > 0) {
      const selectedOptionTimeSlots = Object.entries(this.props.monthlyTimeSlots)
        .filter(([monthId, monthValues]) =>
          filteredTimeSlotsBasedOnService(requiredEntry, [monthId, monthValues])
        )

        .map(([key, value]) => mapTimeSlotsToTheirMonthIds(requiredEntry, [key, value]))
        .reduce(reduceToObject, {});
      this.setState({ newMonthlyTimeSlots: selectedOptionTimeSlots, activeService: label });
    } else {
      this.setState({ newMonthlyTimeSlots: [], activeService: label });
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { bookingStartTime, bookingEndTime, service, animal } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    if (
      bookingStartTime &&
      bookingEndTime &&
      service &&
      animal &&
      !isSameTime &&
      !this.props.fetchLineItemsInProgress
    ) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        monthlyTimeSlots={this.state.newMonthlyTimeSlots}
        filterTimeSlots={this.changeTimeSlots}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            listing,
            filterTimeSlots,
            user,
            animals,
            animalsInProgress,
            animalsError,
          } = fieldRenderProps;

          const currentUser = ensureCurrentUser(user);

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const showAmount = values?.service == config.chargedService;

          // console.log({ showAmount }, 'form');

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                showAmount={showAmount}
                lineItems={lineItems}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const submitDisabled =
            !(
              values?.service &&
              values?.bookingStartTime &&
              values?.bookingStartDate?.date &&
              values?.bookingEndDate?.date &&
              values?.bookingEndTime &&
              values?.animal
            ) || animalsInProgress;

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  filterTimeSlots={filterTimeSlots}
                  pristine={pristine}
                  listing={listing}
                  timeZone={timeZone}
                  currentUser={currentUser}
                  animals={animals}
                  animalsInProgress={animalsInProgress}
                  animalsError={animalsError}
                />
              ) : null}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={submitDisabled}>
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    user: currentUser,
  };
};

const BookingTimeForm = compose(
  injectIntl,
  connect(
    mapStateToProps,
    null
  )
)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
