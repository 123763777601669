import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ActivityFeed } from '../../components';

import css from './TransactionPanel.module.css';
import axios from 'axios';
import { useState } from 'react';
import VideoChatRoom from './VideoChatRoom';
import { useHistory } from 'react-router-dom';
import { IoVideocam } from 'react-icons/io5';
import config from '../../config';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { showToast } from '../../util/toasts';
import { TRANSITION_VIDEO_CALLS_ACCEPT } from '../../util/transaction';

// Functional component as a helper to build ActivityFeed section
const FeedSection = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    currentUser,
    fetchMessagesError,
    fetchMessagesInProgress,
    initialMessageFailed,
    messages,
    oldestMessagePageFetched,
    onShowMoreMessages,
    onOpenReviewModal,
    totalMessagePages,
    currentRole,
  } = props;
  const [connecting, setConnecting] = useState(false);
  const history = useHistory();

  function handleClick(transaction) {
    history.push({
      pathname: '/video-call',
      state: {
        roomName: currentTransaction.id.uuid,
      },
    });
    // setConnecting(true);
    // axios
    //   .post('/video-chat', { transactionId: transaction.id.uuid })
    //   .then(res => {
    //     history.replace({
    //       pathname: '/video-call',
    //       state: {
    //         token: res.data.token,
    //         transaction: JSON.stringify(transaction),
    //       },
    //     });
    //     // setToken(res.data.token);
    //   })
    //   .catch(err => {
    //     showToast(err?.response?.data?.message, 'error');
    //   })
    //   .finally(() => {
    //     setConnecting(false);
    //   });
  }

  const txTransitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;

  const showFeed =
    messages.length > 0 || txTransitions.length > 0 || initialMessageFailed || fetchMessagesError;

  const classes = classNames(rootClassName || css.feedContainer, className);

  const lastTransitions = [TRANSITION_VIDEO_CALLS_ACCEPT];
  const videoCall = currentTransaction.attributes?.protectedData?.service == config.chargedService;
  const bookingStart = currentTransaction?.booking?.attributes?.start;
  const currentDate = new Date();
  const difference = bookingStart
    ? moment.duration(moment(bookingStart).diff(moment(currentDate))).asMinutes()
    : null;

  const showVideoCallButton =
    lastTransitions.includes(currentTransaction?.attributes?.lastTransition) &&
    videoCall &&
    bookingStart &&
    difference <= 10
      ? true
      : false;

  const bookingEnd = currentTransaction?.booking?.attributes?.end;
  const shouldShowCallButton = bookingEnd
    ? moment(new Date()).valueOf() > moment(bookingEnd).valueOf()
      ? false
      : true
    : false;

  return showFeed ? (
    <>
      <div className={classes}>
        <h3 className={css.feedHeading}>
          <FormattedMessage id="TransactionPanel.activityHeading" />
        </h3>
        {initialMessageFailed ? (
          <p className={css.messageError}>
            <FormattedMessage id="TransactionPanel.initialMessageFailed" />
          </p>
        ) : null}
        {fetchMessagesError ? (
          <p className={css.messageError}>
            <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
          </p>
        ) : null}
        <ActivityFeed
          className={css.feed}
          messages={messages}
          transaction={currentTransaction}
          currentUser={currentUser}
          hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
          onOpenReviewModal={onOpenReviewModal}
          onShowOlderMessages={onShowMoreMessages}
          fetchMessagesInProgress={fetchMessagesInProgress}
          currentRole={currentRole}
        />
        {/* TODO: 24-12-2021 re enable check */}
        {
          // showVideoCallButton && shouldShowCallButton &&
          <div
            className={`bg-marketplaceColor inline-block text-white shadow-md rounded hover:bg-marketplaceColorDark ${
              connecting ? 'bg-marketplaceColorDark' : ''
            } transition duration-100`}
          >
            <button
              onClick={() => handleClick(currentTransaction)}
              className="text-white border-none text-sm px-4 py-3 inline-block font-semibold cursor-pointer"
            >
              <IoVideocam className="text-lg" />
              <span className="ml-2">{connecting ? 'Starting' : 'Start'} Video Call</span>
            </button>
          </div>
        }
        {/* {token && <VideoChatRoom token={token} transaction={currentTransaction} />} */}
      </div>
      <ToastContainer />
    </>
  ) : null;
};

export default FeedSection;
