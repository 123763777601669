import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities, ensureCurrentUser } from '../../util/data';
import axios from 'axios';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_CLIENTS_REQUEST = 'app/InboxPage/FETCH_CLIENTS_REQUEST';
export const FETCH_CLIENTS_SUCCESS = 'app/InboxPage/FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_ERROR = 'app/InboxPage/FETCH_CLIENTS_ERROR';

export const ADD_ANIMAL_REQUEST = 'app/InboxPage/ADD_ANIMAL_REQUEST';
export const ADD_ANIMAL_SUCCESS = 'app/InboxPage/ADD_ANIMAL_SUCCESS';
export const ADD_ANIMAL_ERROR = 'app/InboxPage/ADD_ANIMAL_ERROR';
export const CLEAR_PREVIOUS_SUCCESS_STATES = 'app/InboxPage/CLEAR_PREVIOUS_SUCCESS_STATES';

export const FETCH_ALL_ANIMALS_REQUEST = 'app/InboxPage/FETCH_ALL_ANIMALS_REQUEST';
export const FETCH_ALL_ANIMALS_SUCCESS = 'app/InboxPage/FETCH_ALL_ANIMALS_SUCCESS';
export const FETCH_ALL_ANIMALS_ERROR = 'app/InboxPage/FETCH_ALL_ANIMALS_ERROR';

export const UPDATE_ANIMAL_REQUEST = 'app/InboxPage/UPDATE_ANIMAL_REQUEST';
export const UPDATE_ANIMAL_SUCCESS = 'app/InboxPage/UPDATE_ANIMAL_SUCCESS';
export const UPDATE_ANIMAL_ERROR = 'app/InboxPage/UPDATE_ANIMAL_ERROR';

export const ADD_DOCUMENT_REQUEST = 'app/InboxPage/ADD_DOCUMENT_REQUEST';
export const ADD_DOCUMENT_SUCCESS = 'app/InboxPage/ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_ERROR = 'app/InboxPage/ADD_DOCUMENT_ERROR';

export const FETCH_REPORTS_REQUEST = 'app/InboxPage/FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'app/InboxPage/FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_ERROR = 'app/InboxPage/FETCH_REPORTS_ERROR';

export const FETCH_PRESCRIPTIONS_REQUEST = 'app/InboxPage/FETCH_PRESCRIPTIONS_REQUEST';
export const FETCH_PRESCRIPTIONS_SUCCESS = 'app/InboxPage/FETCH_PRESCRIPTIONS_SUCCESS';
export const FETCH_PRESCRIPTIONS_ERROR = 'app/InboxPage/FETCH_PRESCRIPTIONS_ERROR';

export const FETCH_INVOICES_REQUEST = 'app/InboxPage/FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'app/InboxPage/FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_ERROR = 'app/InboxPage/FETCH_INVOICES_ERROR';

export const GET_ANALYTICS_REQUEST = 'app/InboxPage/GET_ANALYTICS_REQUEST';
export const GET_ANALYTICS_SUCCESS = 'app/InboxPage/GET_ANALYTICS_SUCCESS';
export const GET_ANALYTICS_ERROR = 'app/InboxPage/GET_ANALYTICS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],

  //for fetching clients
  clients: [],
  clientsInProgress: true,
  clientsError: null,
  clientsPagination: null,

  //for adding animal
  addAnimalInProgress: false,
  addAnimalError: null,
  addAnimalSuccess: false,

  //for fetching all animals
  animalsInProgress: true,
  animalsError: null,
  animals: [],
  animalsPagination: null,

  //for updating an animal
  updateAnimalInProgress: false,
  updateAnimalError: null,
  updateAnimalSuccess: false,

  //for adding document
  addDocumentInProgress: false,
  addDocumentError: null,
  addDocumentSuccess: false,

  //for fetching documents
  reports: [],
  reportsInProgress: true,
  reportsError: null,
  reportsPagination: null,

  //for fetching prescriptions
  prescriptions: [],
  prescriptionsInProgress: true,
  prescriptionsError: null,
  prescriptionsPagination: null,

  //for fetching invoices
  invoices: [],
  invoicesInProgress: true,
  invoicesError: null,
  invoicesPagination: null,

  //analytics
  analytics: null,
  analyticsInProgress: true,
  analyticsError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_CLIENTS_REQUEST:
      return {
        ...state,
        clientsInProgress: true,
        clients: [],
        clientsError: null,
      };
    case FETCH_CLIENTS_SUCCESS: {
      const { data, pagination } = payload;
      return {
        ...state,
        clientsInProgress: false,
        clients: data,
        clientsError: null,
        clientsPagination: pagination,
      };
    }

    case FETCH_CLIENTS_ERROR:
      return {
        ...state,
        clientsInProgress: false,
        clientsError: payload,
      };

    case ADD_ANIMAL_REQUEST:
      return { ...state, addAnimalInProgress: true, addAnimalError: null, addAnimalSuccess: false };
    case ADD_ANIMAL_SUCCESS:
      return { ...state, addAnimalInProgress: false, addAnimalError: null, addAnimalSuccess: true };
    case ADD_ANIMAL_ERROR:
      return {
        ...state,
        addAnimalInProgress: false,
        addAnimalError: payload,
        addAnimalSuccess: false,
      };
    case CLEAR_PREVIOUS_SUCCESS_STATES:
      return {
        ...state,
        addAnimalInProgress: false,
        addAnimalError: null,
        addAnimalSuccess: false,
        addAnimalSuccess: false,
        updateAnimalInProgress: false,
        updateAnimalError: null,
        updateAnimalSuccess: false,

        addDocumentInProgress: false,
        addDocumentError: null,
        addDocumentSuccess: false,
      };

    case FETCH_ALL_ANIMALS_REQUEST:
      return {
        ...state,
        animalsInProgress: true,
        animalsError: null,
        animals: [],
        animalsPagination: null,
      };
    case FETCH_ALL_ANIMALS_SUCCESS:
      const { animals, pagination } = payload;
      return {
        ...state,
        animalsInProgress: false,
        animalsError: null,
        animals: animals,
        animalsPagination: pagination,
      };
    case FETCH_ALL_ANIMALS_ERROR:
      return {
        ...state,
        animalsInProgress: false,
        animals: [],
        animalsError: payload,
        animalsPagination: null,
      };

    case UPDATE_ANIMAL_REQUEST:
      return {
        ...state,
        updateAnimalInProgress: true,
        updateAnimalError: null,
        updateAnimalSuccess: false,
      };
    case UPDATE_ANIMAL_SUCCESS:
      return {
        ...state,
        updateAnimalInProgress: false,
        updateAnimalError: null,
        updateAnimalSuccess: true,
      };
    case UPDATE_ANIMAL_ERROR:
      return {
        ...state,
        updateAnimalInProgress: false,
        updateAnimalError: payload,
        updateAnimalSuccess: false,
      };

    case ADD_DOCUMENT_REQUEST:
      return {
        ...state,

        addDocumentInProgress: true,
        addDocumentError: null,
        addDocumentSuccess: false,
      };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        addDocumentInProgress: false,
        addDocumentError: null,
        addDocumentSuccess: true,
      };
    case ADD_DOCUMENT_ERROR:
      return {
        ...state,
        addDocumentInProgress: false,
        addDocumentError: payload,
        addDocumentSuccess: false,
      };

    case FETCH_REPORTS_REQUEST:
      return {
        ...state,
        reportsInProgress: true,
        reportsError: null,
        reports: [],
        reportsPagination: null,
      };
    case FETCH_REPORTS_SUCCESS: {
      const { reports, pagination } = payload;
      return {
        ...state,
        reportsInProgress: false,
        reportsError: null,
        reports: reports,
        reportsPagination: pagination,
      };
    }
    case FETCH_REPORTS_ERROR:
      return {
        ...state,
        reportsInProgress: false,
        reports: [],
        reportsError: payload,
        reportsPagination: null,
      };
    case FETCH_PRESCRIPTIONS_REQUEST:
      return {
        ...state,
        prescriptionsInProgress: true,
        prescriptionsError: null,
        prescriptions: [],
        prescriptionsPagination: null,
      };
    case FETCH_PRESCRIPTIONS_SUCCESS: {
      const { prescriptions, pagination } = payload;
      return {
        ...state,
        prescriptionsInProgress: false,
        prescriptionsError: null,
        prescriptions: prescriptions,
        prescriptionsPagination: pagination,
      };
    }
    case FETCH_PRESCRIPTIONS_ERROR:
      return {
        ...state,
        prescriptionsInProgress: false,
        prescriptions: [],
        prescriptionsError: payload,
        prescriptionsPagination: null,
      };

    case FETCH_INVOICES_REQUEST:
      return {
        ...state,
        invoicesInProgress: true,
        invoicesError: null,
        invoices: [],
        invoicesPagination: null,
      };
    case FETCH_INVOICES_SUCCESS: {
      const { invoices, pagination } = payload;
      return {
        ...state,
        invoicesInProgress: false,
        invoicesError: null,
        invoices: invoices,
        invoicesPagination: pagination,
      };
    }
    case FETCH_INVOICES_ERROR:
      return {
        ...state,
        invoicesInProgress: false,
        invoices: [],
        invoicesError: payload,
        invoicesPagination: null,
      };
    case GET_ANALYTICS_REQUEST:
      return {
        ...state,
        analyticsInProgress: true,
        analyticsError: null,
        analytics: null,
      };
    case GET_ANALYTICS_SUCCESS: {
      const { analytics } = payload;
      return {
        ...state,
        analyticsInProgress: false,
        analyticsError: null,
        analytics,
      };
    }
    case GET_ANALYTICS_ERROR:
      return {
        ...state,
        analyticsInProgress: false,
        analyticsError: payload,
        analytics: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchClientsRequest = () => ({ type: FETCH_CLIENTS_REQUEST });
const fetchClientsSuccess = response => ({ type: FETCH_CLIENTS_SUCCESS, payload: response });
const fetchClientsError = e => ({ type: FETCH_CLIENTS_ERROR, payload: e });

const addAnimalRequest = () => ({ type: ADD_ANIMAL_REQUEST });
const addAnimalSuccess = () => ({ type: ADD_ANIMAL_SUCCESS });
const addAnimalError = e => ({ type: ADD_ANIMAL_ERROR, payload: e });

const fetchAllAnimalsRequest = () => ({ type: FETCH_ALL_ANIMALS_REQUEST });
const fetchAllAnimalsSuccess = (animals, pagination) => ({
  type: FETCH_ALL_ANIMALS_SUCCESS,
  payload: { animals, pagination },
});
const fetchAllAnimalsError = e => ({ type: FETCH_ALL_ANIMALS_ERROR, payload: e });

const updateAnimalRequest = () => ({ type: UPDATE_ANIMAL_REQUEST });
const updateAnimalSuccess = payload => ({ type: UPDATE_ANIMAL_SUCCESS, payload });
const updateAnimalError = e => ({ type: UPDATE_ANIMAL_ERROR, payload: e });

export const clearConflicts = () => ({ type: CLEAR_PREVIOUS_SUCCESS_STATES });

const addDocumentRequest = () => ({ type: ADD_DOCUMENT_REQUEST });
const addDocumentSuccess = () => ({ type: ADD_DOCUMENT_SUCCESS });
const addDocumentError = e => ({ type: ADD_DOCUMENT_ERROR, payload: e });

const fetchReportsRequest = () => ({ type: FETCH_REPORTS_REQUEST });
const fetchReportsSuccess = response => ({ type: FETCH_REPORTS_SUCCESS, payload: response });
const fetchReportsError = e => ({ type: FETCH_REPORTS_ERROR, payload: e });

const fetchPrescriptionsRequest = () => ({ type: FETCH_PRESCRIPTIONS_REQUEST });
const fetchPrescriptionsSuccess = response => ({
  type: FETCH_PRESCRIPTIONS_SUCCESS,
  payload: response,
});
const fetchPrescriptionsError = e => ({ type: FETCH_PRESCRIPTIONS_ERROR, payload: e });

const fetchInvoicesRequest = () => ({ type: FETCH_INVOICES_REQUEST });
const fetchInvoicesSuccess = response => ({ type: FETCH_INVOICES_SUCCESS, payload: response });
const fetchInvoicesError = e => ({ type: FETCH_INVOICES_ERROR, payload: e });

const getAnalyticsRequest = () => ({ type: GET_ANALYTICS_REQUEST });
const getAnalyticsSuccess = analytics => ({ type: GET_ANALYTICS_SUCCESS, payload: { analytics } });
const getAnalyticsError = e => ({ type: GET_ANALYTICS_ERROR, payload: e });

// ================ Thunks ================ //

async function getAnalytics(filter) {
  const filterMaybe = filter ? `?filter=${filter}` : '';
  return axios.get(`/analytics${filterMaybe}`);
}

export const fetchAnalytics = filter => async (dispatch, getState) => {
  dispatch(getAnalyticsRequest());
  try {
    const response = await getAnalytics(filter);
    dispatch(getAnalyticsSuccess(response.data));
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    dispatch(getAnalyticsError(error));
  }
};

export const fetchAllAnimals = searchParams => async (dispatch, getState) => {
  let page = searchParams ? new URLSearchParams(searchParams).get('page') : null;
  page = page ? (page == '1' ? null : page) : null;

  dispatch(fetchAllAnimalsRequest());

  try {
    const route = page ? `/animals?page=${page}&withDocuments=true` : '/animals?withDocuments=true';
    const response = await axios.get(route);
    const data = response.data;
    const { animals, pagination } = data;
    dispatch(fetchAllAnimalsSuccess(animals, pagination));
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    dispatch(fetchAllAnimalsError(error));
  }
};

export const fetchReports = searchParams => async (dispatch, getState) => {
  let page = searchParams ? new URLSearchParams(searchParams).get('page') : null;
  page = page ? (page == '1' ? null : page) : null;
  dispatch(fetchReportsRequest());
  try {
    const route = page ? `/docs?page=${page}&type=1` : '/docs?type=1';
    const response = await axios.get(route);
    const data = response.data;
    const { docs: reports, pagination } = data;
    dispatch(fetchReportsSuccess({ reports, pagination }));
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    dispatch(fetchReportsError(error));
  }
};

export const fetchPrescriptions = searchParams => async (dispatch, getState) => {
  let page = searchParams ? new URLSearchParams(searchParams).get('page') : null;
  page = page ? (page == '1' ? null : page) : null;
  dispatch(fetchPrescriptionsRequest());
  try {
    const route = page ? `/docs?page=${page}&type=2` : '/docs?type=2';
    const response = await axios.get(route);
    const data = response.data;
    const { docs: prescriptions, pagination } = data;
    dispatch(fetchPrescriptionsSuccess({ prescriptions, pagination }));
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    dispatch(fetchPrescriptionsError(error));
  }
};

export const fetchInvoices = searchParams => async (dispatch, getState) => {
  let page = searchParams ? new URLSearchParams(searchParams).get('page') : null;
  page = page ? (page == '1' ? null : page) : null;
  dispatch(fetchInvoicesRequest());
  try {
    const route = page ? `/docs?page=${page}&type=0` : '/docs?type=0';
    const response = await axios.get(route);
    const data = response.data;
    const { docs: invoices, pagination } = data;
    dispatch(fetchInvoicesSuccess({ invoices, pagination }));
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    dispatch(fetchInvoicesError(error));
  }
};

export const fetchDocuments = params => async (dispatch, getState) => {
  dispatch(fetchReports());
  dispatch(fetchPrescriptions());
  dispatch(fetchInvoices());
};

export const searchClients = term => async (dispatch, getState) => {
  dispatch(fetchClientsRequest());

  /**
 *   const urlParams = term && sort ? { search: term, sort } : sort ? { sort } : { search: term };

  if (!term && !sort) {
    return;
  }

  // const searchParams = new URLSearchParams(urlParams).toString();

 * 
 */

  try {
    const response = await axios.get(`/search-clients?search=${term}`);
    const data = response.data || {};
    const { results = [], metadata = [] } = data;

    // const animal_id =
    const newResults = results.map(result => ({
      joined_docs: result.joined_docs,
      joined_invoices: [...result.joined_invoices],
      joined_animals: result.joined_animals,
      joined_customer: result.joined_customer?.[0],
    }));

    dispatch(fetchClientsSuccess({ data: newResults, pagination: metadata }));
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    dispatch(fetchClientsError(error));
  }
};

export const addProviderDocument = (
  { attachment, title, id, animal_id, customer_id },
  params
) => async (dispatch, getState, sdk) => {
  dispatch(addDocumentRequest());
  const formData = new FormData();
  formData.append('attachment', attachment);
  formData.append('title', title);
  formData.append('id', id);
  if (animal_id) formData.append('animal_id', animal_id);
  else formData.append('customer_id', customer_id);
  try {
    const response = await axios.post('/provider-attachment-upload', formData);
    dispatch(addDocumentSuccess());
    dispatch(fetchClients(params));
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    dispatch(addDocumentError(error));
  }
};

export const addDocument = ({ attachment, title, fileType, id }, params) => async (
  dispatch,
  getState
) => {
  dispatch(addDocumentRequest());
  const formData = new FormData();
  formData.append('attachment', attachment);
  formData.append('title', title);
  formData.append('fileType', fileType);
  formData.append('id', id);
  try {
    const response = await axios.post('/attachment-upload', formData);
    dispatch(addDocumentSuccess());
    dispatch(fetchAllAnimals(params));
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    dispatch(addDocumentError(error));
  }
};

export const updateAnimal = (animalId, data) => async dispatch => {
  dispatch(updateAnimalRequest());
  try {
    const response = await axios.patch(`/animal?id=${animalId}`, data);
    dispatch(updateAnimalSuccess(response));
    dispatch(fetchAllAnimals());
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    dispatch(updateAnimalError(error));
  }
};

export const addAnimal = animal => async (dispatch, getState) => {
  dispatch(addAnimalRequest());
  try {
    const response = await axios.post('/animals', animal);
    dispatch(addAnimalSuccess());
    console.log('refetching fetching all animals');
    dispatch(fetchAllAnimals());
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    console.log(error);
    dispatch(addAnimalError(error));
  }
};

export const fetchClients = (searchParams, sortBy = null) => async (dispatch, getState) => {
  let page = searchParams ? new URLSearchParams(searchParams).get('page') : null;
  page = page ? (page == '1' ? null : page) : null;

  const sort = sortBy ?? 'desc';
  const sortQuery = sort ?? ['asc', 'desc'].includes(sort) ? `sort=${sort}` : 'sort=desc';

  dispatch(fetchClientsRequest());
  try {
    const route = page ? `/clients?page=${page}&${sortQuery}` : `/clients?${sortQuery}`;
    const response = await axios.get(route);
    const data = response.data || {};
    const { results = [], metadata = [] } = data;

    // const animal_id =
    const newResults = results.map(result => ({
      joined_docs: result.joined_docs,
      joined_invoices: [...result.joined_invoices],
      joined_animals: result.joined_animals,
      joined_customer: result.joined_customer?.[0],
    }));

    dispatch(fetchClientsSuccess({ data: newResults, pagination: metadata }));
  } catch (e) {
    const error = e?.response?.data?.message ?? e?.message;
    dispatch(fetchClientsError(error));
  }
};

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { tab, tab_category } = params;

  // const { userRole } = getState().user;
  dispatch(fetchOrdersOrSalesRequest());

  // console.log(TRANSITIONS, ));

  // const onlyFilterValues = {
  //   orders: 'order',
  //   sales: 'sale',
  // };

  // const onlyFilter = onlyFilterValues[tab];
  // if (!onlyFilter) {
  //   return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  // }

  try {
    const userResponse = await sdk.currentUser.show();
    const users = denormalisedResponseEntities(userResponse);
    const [user] = users;
    const userRole = user.attributes.profile.publicData.userType;

    const only =
      userRole == 'patient' && tab == 'orders'
        ? 'order'
        : userRole == 'doctor' && tab == 'sales'
        ? 'sale'
        : null;

    if (!only) {
      return Promise.reject(new Error('You need to be patient or doctor to view this page'));
    }

    let filterParam;
    switch (tab_category) {
      case 'video_calls':
        filterParam = 'video-calls';
        break;
      case 'appointments':
        filterParam = 'appointments';
        break;
      case 'home_visits':
        filterParam = 'home-visits';
        break;
      case 'messages':
        filterParam = 'enquire';
        break;
      case 'clients': {
        dispatch(fetchClients(search));
        return;
      }
      case 'documents': {
        dispatch(fetchDocuments());
        return;
      }
      case 'animals': {
        dispatch(fetchAllAnimals(search));
        return;
      }
      case 'analytics': {
        dispatch(fetchAnalytics());
        return;
      }
    }

    const { page = 1 } = parse(search);

    const NEW_LAST_TRANSITIONS = filterParam
      ? TRANSITIONS.filter(f => f.startsWith(`transition/${filterParam}`))
      : TRANSITIONS;

    const apiQueryParams = {
      // only: onlyFilter,
      only: only,
      lastTransitions: NEW_LAST_TRANSITIONS,
      // lastTransitions: TRANSITIONS,
      include: [
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
        'listing',
      ],
      'fields.transaction': [
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'protectedData',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
      page,
      per_page: INBOX_PAGE_SIZE,
    };
    const transactionsResponse = await sdk.transactions.query(apiQueryParams);

    dispatch(addMarketplaceEntities(transactionsResponse));
    dispatch(fetchOrdersOrSalesSuccess(transactionsResponse));
    return transactionsResponse;
  } catch (e) {
    dispatch(fetchOrdersOrSalesError(storableError(e)));
    throw e;
  }
};
