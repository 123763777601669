import React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { injectIntl } from '../../util/reactIntl';

const getAnimalId = (animal, customer) => {
  return animal.replace(customer.id.uuid, '').replace(':id:', '');
};

function ViewAnimal({ currentTransaction, defaultAnimal, rootClassName, intl }) {
  const history = useHistory();
  // const [viewAnimal, setViewAnimal] = useState('');
  // const { shared_animals = [] } = currentTransaction.attributes.metadata;
  // const animals = [...(defaultAnimal ? [defaultAnimal] : []), ...shared_animals];

  // const viewAnimal = defaultAnimal;

  const viewAnimalHeading = intl.formatMessage({ id: 'ViewAnimal.viewAnimalHeading' });
  const selectAnimalPlaceholder = intl.formatMessage({ id: 'ViewAnimal.selectAnimalPlaceholder' });
  const viewAnimalButtonText = intl.formatMessage({ id: 'ViewAnimal.viewAnimalButtonText' });

  return (
    <div className={rootClassName}>
      {/* {animals?.length > 0 ? ( */}
      <React.Fragment>
        {/* <h5 className="my-0 text-gray-500 font-semibold">{viewAnimalHeading}</h5> */}
        {/* <select value={viewAnimal} onChange={e => setViewAnimal(e.target.value)}>
            <option value="">{selectAnimalPlaceholder}</option>
            {animals.map(animal => (
              <option key={animal} value={animal}>
                {getAnimalId(animal, currentTransaction.customer)}
              </option>
            ))}
          </select> */}

        <p
          className={classNames(
            `text-white py-4 text-center font-semibold rounded shadow focus:bg-gray-800 hover:bg-gray-800  my-0 text-sm mt-4 font-bold cursor-pointer bg-black ${
              defaultAnimal
                ? ''
                : 'bg-gray-200 border-gray-200 cursor-not-allowed hover:bg-gray-200'
            }`
          )}
          onClick={() => {
            // const [animalId, uid] = viewAnimal.split(':id:');
            // if (!animalId || !uid) return;
            if (!defaultAnimal) return;
            history.push({
              // pathname: `/view/${uid}/${animalId}`,
              pathname: `/view/${defaultAnimal}`,
              state: {
                transaction: JSON.stringify(currentTransaction),
              },
            });
          }}
        >
          {viewAnimalButtonText}
        </p>
      </React.Fragment>
      {/* ) : null} */}
    </div>
  );
}

export default injectIntl(ViewAnimal);
