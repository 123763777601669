import React from 'react';
import { Field } from 'react-final-form';

const ACCEPT_FILES =
  'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

function FieldFilePicker(props) {
  const { name, label, formApi, acceptFiles, formValues } = props;

  let accept;
  switch (acceptFiles) {
    case 'images':
      accept = 'image/png,image/jpg,image/jpeg';
      break;
    default:
      accept = ACCEPT_FILES;
  }

  return (
    <div className="mb-2">
      {/* <span>{label}</span> */}
      <div
        onDragEnd={e => e.preventDefault()}
        onDragOverCapture={e => e.preventDefault()}
        onDrop={e => {
          formApi.change(name, e.dataTransfer.files[0]);
        }}
        className="relative cursor-pointer h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer"
      >
        <div className="absolute cursor-pointer">
          <div className="flex flex-col items-center ">
            {' '}
            <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{' '}
            {/* <span className="block text-gray-400 font-normal">Attach you files here</span>{' '}
            <span className="block text-gray-400 font-normal">or</span>{' '} */}
            <span className="block text-blue-400 font-normal">
              {formValues[name] ? formValues[name].name : 'Browse files'}
            </span>{' '}
          </div>
        </div>{' '}
        <Field name={name} accept={ACCEPT_FILES}>
          {({ input: { value, onChange, ...rest } }) => {
            const handleChange = event => {
              const file = event.target.files[0];
              onChange(file);
              if (typeof onSelectFile != 'undefined') {
                onSelectFile(file);
              }
            };
            return (
              <input
                {...rest}
                onChange={handleChange}
                accept={accept}
                type="file"
                className="h-full w-full opacity-0 cursor-pointer"
              />
            );
          }}
        </Field>
      </div>
      {/* <div className="flex justify-between items-center text-gray-400">
        {' '}
        <span>Accepted file type:.doc only</span>{' '}
        <span className="flex items-center ">
          <i className="fa fa-lock mr-1"></i> secure
        </span>{' '}
      </div> */}
    </div>
  );
}

export default FieldFilePicker;
