import React from 'react';
import css from './LoadingPage.module.css';
import {
  Footer,
  IconSpinner,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { connect } from 'react-redux';
const LoadingPageComponent = props => {
  const { scrollingDisabled } = props;
  return (
    <div>
      <Page
        scrollingDisabled={scrollingDisabled}
        title="Loading ..."
        contentType="website"
        description="Loading ..."
        schema={{
          '@context': 'https://schema.org',
          '@type': 'ItemPage',
          description: 'Loading...',
          name: 'Loading ...',
          image: 'Loading ...',
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>{/* <TopbarContainer /> */}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.loadingContainer}>
              <IconSpinner className={css.loading} />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

export const LoadingPage = connect(
  mapStateToProps,
  null
)(LoadingPageComponent);
