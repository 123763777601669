import React from 'react';
import css from './LoadingPage.module.css';
import {
  Footer,
  IconSpinner,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '..';

const LoadingPage = props => {
  return (
    // <Page
    //   title="Loading ..."
    //   // scrollingDisabled={scrollingDisabled}
    //   // author={authorDisplayName}
    //   contentType="website"
    //   description="Loading ..."
    //   // facebookImages={facebookImages}
    //   // twitterImages={twitterImages}
    //   schema={{
    //     '@context': 'http://schema.org',
    //     '@type': 'ItemPage',
    //     description: 'Loading...',
    //     name: 'Loading ...',
    //     image: 'Loading ...',
    //   }}
    // >
    //   <LayoutSingleColumn>
    //     <LayoutWrapperTopbar>
    //       <TopbarContainer />
    //     </LayoutWrapperTopbar>
    //     <LayoutWrapperMain>
    <div className={css.loadingContainer}>
      <IconSpinner className={css.loading} />
    </div>
    //     </LayoutWrapperMain>
    //     <LayoutWrapperFooter>
    //       <Footer />
    //     </LayoutWrapperFooter>
    //   </LayoutSingleColumn>
    // </Page>
  );
};

export default LoadingPage;
