import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Imprint.module.css';

const Imprint = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: July 1th, 2023</p>
      <h1>Imprint</h1>
      <h2>1. According to § 5 TMG</h2>
      <ul className={css.listIndent}>
        <li>aVet UG (limited liability)</li>
        <li>Vogelsbergstraße 22</li>
        <li>60316 Frankfurt am Main</li>
        <li>Represented by:</li>
        <li>Tim Guntermann and Zoe Zellner</li>
        <li>Entry in the Commercial Register | Registration Court: Frankfurt am Main, Germany | HRB: 129628</li>
        <li>VAT identification number according to §27a of the Value Added Tax Act (Umsatzsteuergesetz): DE358191517</li>
        </ul>

      <h2>2. Liability for content</h2>
      <p>The contents of our pages were created with great care. However, we cannot guarantee that the content is correct, complete or up-to-date. As a service provider, we are responsible for our own content on these pages according to Section 7, Paragraph 1 of the German Telemedia Act (TMG). According to §§ 8 to 10 TMG, however, we as a service provider are not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information according to general laws remain unaffected. However, liability in this regard is only possible from the point in time at which knowledge of a specific infringement of the law is known. As soon as we become aware of any violations of the law, we will remove this content immediately.</p>
      <h2>3. Liability for links</h2>
      <p>Our offer contains links to external third-party websites, the content of which we have no influence on. Therefore we cannot assume any liability for this external content. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal content was not recognizable at the time of linking. However, a permanent control of the content of the linked pages is not reasonable without concrete evidence of an infringement. As soon as we become aware of legal violations, we will remove such links immediately.</p>
      <h2>4. Copyright</h2>
      <p>The content and works on these pages created by the site operators are subject to German copyright law. The duplication, editing, distribution and any kind of exploitation outside the limits of copyright require the written consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are observed. In particular contents of third parties are marked as such. Should you nevertheless become aware of a copyright infringement, we ask that you inform us accordingly. As soon as we become aware of legal violations, we will remove such content immediately.</p>
      <h2>5. Data Protection</h2>
      <p>Our website can usually be used without providing any personal data. Insofar as personal data (e.g. name, address or e-mail addresses) is collected on our website, this is always done on a voluntary basis as far as possible. This data will not be passed on to third parties without your express consent.
            We would like to point out that data transmission on the Internet (e.g. when communicating by e-mail) can have security gaps. A complete protection of the data against access by third parties is not possible. The use of contact data published as part of the imprint obligation by third parties to send unsolicited advertising and information material is hereby expressly prohibited. The site operators expressly reserve the right to take legal action in the event of unsolicited advertising being sent, such as spam e-mails.
        </p>
      <h2>6. Google Analytics</h2>
      <p>This website uses Google Analytics, a web analytics service provided by Google Inc. (''Google''). Google Analytics uses so-called ''cookies'', text files that are stored on your computer and enable an analysis of your use of the website. The information generated by the cookie about your use of this website (including your IP address) is transmitted to a Google server in the USA and stored there. Google will use this information to evaluate your use of the website, to compile reports on website activity for website operators and to provide other services related to website activity and internet usage. Google may also transfer this information to third parties if required to do so by law or if third parties process this data on Google's behalf. Under no circumstances will Google associate your IP address with other Google data. You can prevent the installation of cookies by setting your browser software accordingly; we would like to point out to you however that in this case you can if applicable not use all functions of this website in full. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
      <h2>7. Google AdSense</h2>
      <p>This website uses Google Adsense, a web advertising service provided by Google Inc., USA (''Google''). Google Adsense uses so-called "cookies" (text files), which are stored on your computer and which enable an analysis of your use of the website. Google Adsense also uses so-called ''Web Beacons'' (small invisible graphics) to collect information. By using the web beacon, simple actions such as visitor traffic on the website can be recorded and collected. The information generated by the cookie and/or web beacon about your use of this website (including your IP address) is transmitted to a Google server in the USA and stored there. Google will use this information to evaluate your use of the website with regard to the ads, to compile reports on website activity and ads for the website operator and to provide other services related to website activity and internet usage. Google may also transfer this information to third parties if required to do so by law or if third parties process this data on Google's behalf. Under no circumstances will Google associate your IP address with other Google data. You can prevent the storage of cookies on your hard drive and the display of web beacons by selecting "Do not accept cookies" in your browser settings (in MS Internet Explorer under "Extras - Internet options - Data protection - Settings"; in Firefox under "Extras - Settings - Data protection - Cookies"); we would like to point out to you however that in this case you can if applicable not use all functions of this website in full. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
    </div>
  );
};

Imprint.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

Imprint.propTypes = {
  rootClassName: string,
  className: string,
};

export default Imprint;
