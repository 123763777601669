import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Form } from '../../components';
import FieldFilePicker from './FieldFilePicker';
import { FieldSelect, FieldTextInput, Modal } from '../../components';
import config from '../../config';
import IconSpinner from '../IconSpinner/IconSpinner';
import css from './TransactionPanel.module.css';
import { showToast } from '../../util/toasts';

function SendFilesFormComponent(props) {
  const {
    showShareFilesModal,
    onManageDisableScrolling,
    setShowShareFilesModal,
    onDestroy,
    isSuccess,
    ...rest
  } = props;

  useEffect(() => {
    if (isSuccess) {
      showToast('Document added successfully', 'success');
      setShowShareFilesModal(null);
    }
    return () => {
      if (onDestroy) onDestroy();
    };
  }, [isSuccess]);

  return (
    <Modal
      id="share-files-modal"
      name="share-files-modal"
      isOpen={showShareFilesModal != null}
      onManageDisableScrolling={onManageDisableScrolling}
      onClose={() => setShowShareFilesModal(null)}
      usePortal
      contentClassName={css.modalContent}
    >
      <div className={css.modalContainer}>
        <FinalForm
          {...rest}
          showShareFilesModal={showShareFilesModal}
          render={fieldRenderProps => {
            const {
              rootClassName,
              className,
              formId,
              handleSubmit,
              intl,
              invalid,
              values,
              form,
              inProgress,
              sendFilesError,
              showSelector,
              showShareFilesModal,
              label,
            } = fieldRenderProps;

            const { type } = showShareFilesModal;

            const classes = classNames(rootClassName || css.root, className);
            const submitDisabled =
              invalid || inProgress || !values?.attachment?.length <= 0 || showSelector == true
                ? !values?.fileType
                : false || !values?.title;

            const titlePlaceholder =
              type != 'invoice'
                ? intl.formatMessage({ id: 'SendFilesForm.titlePlaceholder' })
                : 'Example: Last month invoice';
            const titleLabel = intl.formatMessage({ id: 'SendFilesForm.titleLabel' });
            const selectLabel = intl.formatMessage({ id: 'SendFilesForm.selectLabel' });
            const selectPlaceholder = intl.formatMessage({ id: 'SendFilesForm.selectPlaceholder' });
            const sendButtonText = intl.formatMessage({ id: 'SendFilesForm.sendButtonText' });

            return (
              <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
                <FieldFilePicker
                  formApi={form}
                  name="attachment"
                  id="attachment"
                  formValues={values}
                />

                <FieldTextInput
                  name="title"
                  id="title"
                  label={titleLabel}
                  placeholder={titlePlaceholder}
                  className="mb-4"
                />
                {showSelector && (
                  <FieldSelect className="mb-4" id="fileType" name="fileType" label={selectLabel}>
                    <option disabled value="">
                      {selectPlaceholder}
                    </option>
                    {config.custom.SHARE_ATTACHMENT_TYPES.map(p => {
                      return (
                        <option key={p.key} value={p.key}>
                          {p.label}
                        </option>
                      );
                    })}
                  </FieldSelect>
                )}
                {sendFilesError && <span className="text-sm text-red-500">{sendFilesError}</span>}
                <button
                  type="submit"
                  className={`bg-marketplaceColor border border-solid border-marketplaceColor text-white mt-4 w-full inline-block cursor-pointer py-2 rounded focus:bg-marketplaceColorDark hover:bg-marketplaceColorDark hover:border-marketplaceColorDark font-semibold tracking-wider ${
                    inProgress
                      ? 'disabled:bg-marketplaceColorLight disabled:border-marketplaceColorLight disabled:cursor-not-allowed'
                      : 'disabled:bg-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed'
                  }`}
                  disabled={submitDisabled}
                >
                  {inProgress ? (
                    <IconSpinner className="text-marketplaceColorDark" />
                  ) : (
                    sendButtonText
                  )}
                </button>
              </Form>
            );
          }}
        />
      </div>
    </Modal>
  );
}

SendFilesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  showSelector: true,
};

const { bool, func, string } = PropTypes;

SendFilesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
};

const SendFilesForm = compose(injectIntl)(SendFilesFormComponent);

SendFilesForm.displayName = 'SendFilesForm';

export default SendFilesForm;
