import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import axios from 'axios';
import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;
  const [initialProps, setInitialProps] = useState({});

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;
  let start = 1950;
  let years = [];
  while (start <= new Date().getFullYear()) {
    years.push(start);
    start++;
  }
  const sinceDateOptions = years?.map(item => ({ key: item, label: item, value: item }));

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  let initialValues = {
    title,
    description,
    sinceDate: sinceDateOptions?.find(item => item.key === publicData?.sinceDate),
    organizationAddress: publicData?.organizationAddress,
    organizationName: publicData?.organizationName,
    organization: config.custom.organizations.find(item => item.value === publicData?.organization),
    certificate: publicData?.certificate,
    doctorate: publicData?.doctorate,
  };
  if (updateInProgress) {
    initialValues = { ...initialValues, ...initialProps };
  }
  // console.log('initialValues', initialValues);
  // const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        sinceDateOptions={sinceDateOptions}
        onSubmit={values => {
          const {
            title,
            description,
            sinceDate,
            organizationAddress,
            organizationName,
            organization,
            certificate,
            doctorate,
          } = values;

          const updateValues = {
            title: title.trim(),
            description,
            publicData: {
              sinceDate: sinceDate.key,
              organizationAddress,
              organizationName,
              organization: organization?.value,
              certificate,
              doctorate,
            },
          };
          setInitialProps({
            ...updateValues,
            title,
            description,
            sinceDate,
            organizationAddress,
            organizationName,
            organization,
            certificate,
            doctorate,
          });
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        currentListing={currentListing}
        // certificateOptions={certificateOptions}
        // uploadFile={uploadFile}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
