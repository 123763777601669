import React, { useEffect, useState } from 'react';
import css from './AddAnimalForm.module.css';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  Button,
  FieldTextInput,
  FieldDatePicker,
  Modal,
  PrimaryButton,
} from '../../components';
import { required } from '../../util/validators';
import { showToast } from '../../util/toasts';

const AddAnimalForm = ({
  isOpen,
  onClose,
  onManageDisableScrolling,
  isSuccess,
  onDestroy,
  id,
  name,
  ...rest
}) => {
  // useEffect(() => {
  //   return () => {
  //     if (onDestroy) {
  //       onDestroy();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      showToast(
        rest.initialValues ? 'Animal updated successfully' : 'Animal added successfully',
        'success'
      );
    }

    return () => {
      if (onDestroy) {
        console.log('onDestroy Called');
        onDestroy();
      }
    };
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      id={id}
      name={name}
    >
      <FinalForm
        {...rest}
        render={formRenderProps => {
          const {
            handleSubmit,
            submitInProgress,
            errors,
            pristine,
            inProgress,
            inError,
          } = formRenderProps;
          const submitDisabled =
            pristine || submitInProgress || Object.keys(errors).length > 0 || inProgress;
          return (
            <Form onSubmit={handleSubmit}>
              <div>
                <FieldTextInput
                  className={css.title}
                  id="animal_name"
                  name="name"
                  type="text"
                  label="Animal Name"
                  placeholder="Enter animal name"
                  validate={required('Animal name is required')}
                />
                <FieldTextInput
                  className={css.title}
                  id="animal_breed"
                  name="breed"
                  type="text"
                  label="Breed"
                  placeholder="Enter animal breed"
                  validate={required('Animal breed is required')}
                />
                <FieldTextInput
                  className={css.title}
                  id="animal_race"
                  name="race"
                  type="text"
                  label="Race"
                  placeholder="Enter race"
                  validate={required('Race is required')}
                />
                <FieldTextInput
                  className={css.title}
                  id="animal_species"
                  name="species"
                  type="text"
                  label="Species"
                  placeholder="Enter animal species"
                  validate={required('Species is required')}
                />
                <div className="abc">
                  <FieldDatePicker
                    className={css.title}
                    id="animal_dob"
                    name="dob"
                    label="Date Of Birth"
                    placeholder="Select dob"
                    maxDate={new Date()}
                    validate={required('Date Of Birth is Required')}
                  />
                </div>
                <FieldTextInput
                  className={css.title}
                  id="animal_additional_info"
                  name="additional_info"
                  type="textArea"
                  label="Additional Information"
                  placeholder="Enter additional information"
                />
              </div>
              {inError && <span className="text-sm text-red-500">{inError}</span>}
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={inProgress}
                disabled={submitDisabled}
              >
                Save
              </PrimaryButton>
              {/* </Modal> */}
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default AddAnimalForm;
