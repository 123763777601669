import React from 'react';
import { IconSpinner } from '../../components';
import css from './FieldDateAndTimeInput.module.css';
import { FieldSelect, NamedLink } from '../../components';

function AnimalSelector({ loading, error, items, formId, values }) {
  if (loading)
    return (
      <div className={css.formRow}>
        <div className={css.servicefield}>
          <IconSpinner />
        </div>
      </div>
    );

  if (error)
    return (
      <div className={css.formRow}>
        <div className={css.servicefield} style={{ lineHeight: '1.5' }}>
          <span className="text-sm text-red-500">{error}</span>
        </div>
      </div>
    );

  if (items.length == 0)
    <div className={css.formRow}>
      <div className={css.servicefield} style={{ lineHeight: '1.5' }}>
        <NamedLink
          name="InboxPageNew"
          className="text-sm mt-4 text-center "
          params={{ tab: 'orders', tab_category: 'animals' }}
        >
          You don't have any animals. Click here to add one.
        </NamedLink>
      </div>
    </div>;

  return (
    <div className={css.formRow}>
      <div className={css.servicefield}>
        <FieldSelect
          id={formId ? `${formId}.animal` : 'animal'}
          name="animal"
          className={css.fieldSelectService}
          selectClassName={css.serviceSelect}
          label={'Please select an animal'}
          disabled={!values?.service}
        >
          <option disabled value="">
            Select an animal
          </option>
          {items.map(animal => (
            <option key={animal._id} value={animal._id}>
              {animal.name}
            </option>
          ))}
        </FieldSelect>
      </div>
    </div>
  );
}

export default AnimalSelector;
