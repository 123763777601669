import React, { useMemo, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import axios from 'axios';
import { FaUpload } from 'react-icons/fa';
import {
  maxLength,
  required,
  composeValidators,
  phoneNumberValidate,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldSelectModern,
  FieldDatePicker,
  FieldFileUpload,
  IconSpinner,
} from '../../components';
import CustomCertificateSelectFieldMaybe from './CustomCertificateSelectFieldMaybe';
import config from '../../config';
import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        certificateOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        sinceDateOptions,
        // uploadFile,
        invalid,
        form,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        currentListing,
      } = formRenderProps;
      const [certificateLoading, setCertificateLoading] = useState(false);
      const [doctorateLoading, setDoctorateLoading] = useState(false);
      const [uploadError, setUploadError] = useState(null);
      const id = currentListing?.id?.uuid ?? 'null';
      const deleteCertificate = useMemo(() => {
        return values?.certificate ? values?.certificate : null;
      }, [values?.certificate]);
      const deleteDoctorate = useMemo(() => {
        return values?.doctorate ? values?.doctorate : null;
      }, [values?.certificate]);
      ///////////////////

      // const uploadFile = async (file, field) => {
      //   console.log('file', file);
      //   const deleteItem = field === 'certificate' ? deleteCertificate : deleteDoctorate;
      //   console.log('delete', deleteItem);
      //   const formData = new FormData();
      //   formData.append('file', file);
      //   if (typeof deleteItem == 'object' && Object.keys(deleteItem).length > 0)
      //     formData.append('item', deleteItem);
      //   // formData.append('listingId', currentListing.id.uuid);
      //   setUploadError(null);
      //   if (field === 'certificate') {
      //     setCertificateLoading(true);
      //   } else {
      //     setDoctorateLoading(true);
      //   }
      //   // console.log('formData', formData);

      //   try {
      //     const res = await axios.post('/upload-file', formData);
      //     console.log('res', res.data.link);

      //     form.change(field, res.data.link);
      //     setUploadError(null);
      //     setDoctorateLoading(false);
      //     setCertificateLoading(false);
      //   } catch (e) {
      //     console.log(e);
      //     form.change(field, '');
      //     setUploadError('Failed to upload the file. Please try again.');
      //   } finally {
      //     setDoctorateLoading(false);
      //     setCertificateLoading(false);
      //   }
      // };
      const uploadFile = async (file, field) => {
        const deleteItem = field === 'certificate' ? deleteCertificate : deleteDoctorate;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('item', deleteItem);
        formData.append('listingId', id);
        formData.append('field', field);
        setUploadError(null);
        if (field === 'certificate') {
          setCertificateLoading(true);
        } else {
          setDoctorateLoading(true);
        }
        // console.log('formData', formData);

        try {
          const res = await axios.post('/upload-file', formData);
          form.change(field, res.data.link);
          setUploadError(null);
          setDoctorateLoading(false);
          setCertificateLoading(false);
        } catch (e) {
          console.log(e);
          form.change(field, '');
          setUploadError('Failed to upload the file. Please try again.');
        } finally {
          setDoctorateLoading(false);
          setCertificateLoading(false);
        }
      };
      /////////////////
      // console.log('value', values);
      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const organizationMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.organizationMessage',
      });
      const organizationPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.organizationPlaceholder',
      });
      const organizationRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.organizationRequired',
      });
      const organizationNameMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.organizationNameMessage',
      });
      const organizationNamePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.organizationNamePlaceholder',
      });
      const organizationNameRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.organizationNameRequired',
      });

      const organizationAddressMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.organizationAddressMessage',
      });
      const organizationAddressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.organizationAddressPlaceholder',
      });
      const organizationAddressRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.organizationAddressRequired',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });
      const sinceDateMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.sinceDateMessage',
      });
      const sinceDatePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.sinceDatePlaceholder',
      });
      const sinceDateRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.sinceDateRequired',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || !values?.certificate;
      //  || !values?.doctorate;
      // console.log(values);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldSelectModern
            className={css.title}
            id="organization"
            name="organization"
            label={organizationMessage}
            options={config.custom.organizations}
            placeholder={organizationPlaceholderMessage}
            isSearchable={false}
            validate={requiredFieldArrayCheckbox(organizationRequiredMessage)}
          />

          <FieldTextInput
            id="organizationName"
            name="organizationName"
            className={css.title}
            type="text"
            label={organizationNameMessage}
            placeholder={organizationNamePlaceholderMessage}
            validate={composeValidators(required(organizationNameRequiredMessage))}
          />
          <FieldTextInput
            id="organizationAddress"
            name="organizationAddress"
            type="text"
            className={css.title}
            label={organizationAddressMessage}
            placeholder={organizationAddressPlaceholderMessage}
            validate={required(organizationAddressRequiredMessage)}
          />
          <FieldTextInput
            id="description"
            name="description"
            type="textarea"
            className={css.title}
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={required(descriptionRequiredMessage)}
          />
          {/* <FieldDatePicker
            id="sinceDate"
            name="sinceDate"
            className={css.title}
            label={sinceDateMessage}
            placeholder={sinceDatePlaceholderMessage}
            validate={required(sinceDateRequiredMessage)}
          /> */}
          <FieldSelectModern
            className={css.title}
            id="sinceDate"
            name="sinceDate"
            label={sinceDateMessage}
            placeholder={sinceDatePlaceholderMessage}
            options={sinceDateOptions}
            isSearchable={false}
            validate={requiredFieldArrayCheckbox(organizationRequiredMessage)}
          />
          <h3>Please upload your Cetrificate</h3>

          <FieldFileUpload
            className={css.title}
            name="certificate"
            id="certificate"
            label={
              <div className={css.file}>
                {certificateLoading ? (
                  <IconSpinner />
                ) : (
                  <div>
                    <div className={css.logo}>
                      {typeof values?.certificate == 'string' ? (
                        <a target="_blank" className={css.link} href={values?.certificate}>
                          {values?.certificate}
                        </a>
                      ) : (
                        <span className={css.uploadLabel}> Upload file</span>
                      )}
                      <FaUpload />
                    </div>
                  </div>
                )}
              </div>
            }
            center={false}
            showUploading={false}
            // acceptFiles="images"
            // uploading={uploadInProgress}
            onSelectFile={file => {
              // console.log('memo', deleteCertificate);
              uploadFile(file, 'certificate');
            }}
          />

          <h3>Please upload your Doctorate</h3>
          <FieldFileUpload
            className={css.title}
            name="doctorate"
            id="doctorate"
            label={
              <div className={css.file}>
                {doctorateLoading ? (
                  <IconSpinner />
                ) : (
                  <div>
                    <div className={css.logo}>
                      {typeof values?.doctorate == 'string' ? (
                        <a target="_blank" className={css.link} href={values?.doctorate}>
                          {values?.doctorate}
                        </a>
                      ) : (
                        <span className={css.uploadLabel}> Upload file</span>
                      )}
                      <FaUpload />
                    </div>
                  </div>
                )}
              </div>
            }
            center={false}
            showUploading={false}
            // acceptFiles="images"
            // uploading={true}
            onSelectFile={file => {
              // console.log('memo doc', deleteDoctorate);
              uploadFile(file, 'doctorate');
            }}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
