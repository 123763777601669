import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingFeaturesForm.module.css';
import { required, requiredCheckBox, requiredFieldArrayCheckbox } from '../../util/validators';
import { compose } from 'redux';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        intl,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        invalid,
        values,
      } = formRenderProps;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;
      const specialisedMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.specialisedMessage',
      });
      const specialisedRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.specialisedRequiredMessage',
      });
      const servicesMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.servicesMessage',
      });
      const servicesRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.servicesRequiredMessage',
      });
      const speciesMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.speciesMessage',
      });
      const speciesRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.speciesRequiredMessage',
      });
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <h2>{specialisedMessage}</h2>
          <FieldCheckboxGroup
            className={css.features}
            id="specialisedOptions"
            name="specialisedOptions"
            options={config.custom.specialisedOptions}
            validate={requiredFieldArrayCheckbox(specialisedRequiredMessage)}
            twoColumns={config.custom.specialisedOptions.length > 5}
          />
          <h2>{servicesMessage}</h2>
          <FieldCheckboxGroup
            className={css.features}
            id="services"
            name="services"
            options={config.custom.services}
            validate={requiredFieldArrayCheckbox(servicesRequiredMessage)}
          />
          <h2>{speciesMessage}</h2>
          <FieldCheckboxGroup
            className={css.features}
            id="species"
            name="species"
            options={config.custom.species}
            validate={requiredFieldArrayCheckbox(speciesRequiredMessage)}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
